.CartoonAnswerButton {
    display: inline-block;
    width: 48%;
    height: 120px;
    position: relative;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 15px;
    vertical-align: middle;
    &:hover {
        .button-text {
            background: #d6de23;
        }
    }
    &.selected {
        .button-text {
            // padding-left: 174px;
            background: #d6de23;
            // border: 6px solid #00aec1;
        }
    }
    &:nth-of-type(odd) {
        margin-right: 4%;
    }
    &.highlight-incorrect {
        opacity: 0.6;
        pointer-events: none;
        &.selected {
            opacity: 1;
            .button-text {
                padding-left: 174px;
                border: 6px solid #f16536;
            }
        }
    }
    &.highlight-correct {
        opacity: 1;
        pointer-events: none;
        .button-text {
            padding-left: 174px;
            border: 6px solid #8BCE2C;
        }
    }
    .button-icon {
        position: absolute;
        height: 120px;
        width: 120px;
        line-height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 100px;
            // width: 100px;
        }
    }
    .button-text {
        display: flex;
        align-items: center;
        background: #e2f2f0;
        height: 120px;
        border-radius: 60px;
        padding-left: 180px;
        font-size: 1.2em;
        transition: background .3s;
    }
}