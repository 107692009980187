.RankingTable {
    width: 100%;
    tr {
        td:first-of-type {
            padding: 10px;
        }
    }
    .ant-table-thead {
        .green-label:before {
            display: inline-block;
            content: "";
            background: #F05A28;
            height: 10px;
            width: 10px;
            vertical-align: middle;
            margin-right: 5px;
        }
        .red-label:before {
            display: inline-block;
            content: "";
            background: #f05a28;
            height: 10px;
            width: 10px;
            vertical-align: middle;
            margin-right: 5px;
        }
    }
    .crown-img {
        width: 30px;
    }
}