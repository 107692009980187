.Footer {
    position: relative;
    width: 100%;
    margin-top: 200px;
    .footer-section {
        position: relative;
        background: #8BCE2C;
        &:after {
            content: '';
            display: block;
            clear: both;
        }
        .footer-container {
            position: relative;
            max-width: 1200px;
            margin: auto;
            z-index: 3;
            padding: 10px 0px;
            .footer-logo {
                width: 250px;
                margin-bottom: 50px;
            }
            .footer-right {
                float: right;
                .icon-btn {
                    float: left;
                    margin-right: 10px;
                    background: #1C3D74;
                    color: white;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    font-size: 2em;
                    border-radius: 50%;
                    &:hover {
                        background: #F05A28;
                    }
                }
            }
        }
        .footer-earth {
            position: absolute;
            overflow: hidden;
            height: 500px;
            width: 100%;
            bottom: 0;
            margin-top: -200px;
            .earth-wrapper {
                position: relative;
                .layer {
                    position: absolute;
                    top: 0;
                    &.layer1 {
                        z-index: 1;
                    }
                    &.layer2 {
                        z-index: -1;
                    }
                    &.rotating {
                        animation: rotating 120s linear infinite;
                    }
                }
            }
        }
    }
    .footer-section-4 {
        z-index: 2;
        height: 180px;
        overflow: hidden;
        background: url('./footer-bg-r.png') right bottom no-repeat, url('./footer-bg-l.png') left top no-repeat;
        background-size: contain, contain;
        .slogan {
            position: absolute;
            left: 8%;
            bottom: 15px;
            z-index: 2;
            img {
                height: 100px;
            }
        }
        .plastic-bag-wrapper {
            left: 0;
            right: 0;
            margin-top: -90px;
            .plastic-bag {
                position: absolute;
                top: 30%;
                left: 0;
                height: 60px;
                z-index: 1;
            }
        }
    }
    .footer-section-2 {
        background: #1c3d74;
        color: #fff;
        z-index: 5;
        padding: 7px 0;
        font-size: .8em;
        a {
            color: #fff;
            text-decoration: none;
            &:hover,
            &:focus {
                color: #ffc10e;
            }
        }
        .footer-section-2-wrapper {
            margin: auto;
            width: 100%;
            max-width: 1200px;
            padding: 0 15px;
            text-align: center;
        }
    }
    .footer-section-3 {
        text-align: center;
        font-size: 10px;
        line-height: 1.1;
        background: #e2f2f0;
        color: #b3b3b3;
        z-index: 5;
        padding: 5px 15px;
    }
    .hidden {
        display: none;
    }
}

@keyframes rotating {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}